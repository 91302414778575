import { memo, useEffect, useState, useContext, useRef } from 'react';
import { SaveFav } from '../inc/functions'
import { Input } from "../components/Form";
import { Link } from "react-router-dom";
import TextTruncate from 'react-text-truncate';
import { useCookies } from 'react-cookie'

import '../pages/popups/css/QuizPrev.css'

import { Config } from '../inc/Config'
import { AppContext } from "../Context";
import { PlaySound } from "../inc/Fetch";

import { GoCheckCircleFill, GoCircle, GoTriangleDown } from "react-icons/go";
import { IoIosArrowBack, IoMdPlayCircle } from "react-icons/io";
import { IoLockClosed } from "react-icons/io5";


export function SpeackBtn({text}) {


    return (
        <a style={{fontSize:70, display:"table", margin:"auto"}} onClick={() => PlaySound(text)}><IoMdPlayCircle /></a>
    );
} 

export function LanguageSelect() {

    const [cookies, setCookie] = useCookies(['lang']);

    const {language} = useContext(AppContext);
    const [lang, setlang] = language;

    const [langMenu, setlangMenu] = useState(false);

    function changeLang(la) {
        let expires = new Date()
        expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000))


        setCookie('lang', la, { path: '/',  expires});
        window.location.reload();
        // setlang(la);
        setlangMenu(false);
    }

    return (
        <ul className='languageSelectBox'>
            <li onClick={() => setlangMenu(p => !p)}>{lang} <GoTriangleDown /></li>
            <template className={langMenu ? "open" : undefined}>
                <a onClick={() => changeLang("EN")}>English</a>
                <a onClick={() => changeLang("AR")}>العربية</a>
            </template>
            
        </ul>
    );
}

export function Question({v, answers}) {

    

    const {userinfo, showQuestion} = useContext(AppContext);
    const [logedIn, setlogedIn] = userinfo;
    const [showQ, setshowQ] = showQuestion;

    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url?.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    let yID = youtube_parser(v?.video?.video_url);

    let letCheck2 = [];
    let letCheck2Ans = "";
    if (v?.answersType == 2) {

        let letCheckAns = v?.answers[0];
        if (answers) {
            let letCheck = answers?.filter(p => p?.question == v?.ID);
            
    
            if (letCheck[0]?.answer) {
                letCheck2 = letCheck[0]?.answer?.split("");
            }
    
            
        }

        letCheck2Ans = letCheckAns?.answer;
        
    }


    
    let audio = v?.answers?.filter(r => r?.true == true)[0]?.answer;

    return (
        <div className='question' data-id={v?.ID}>
            {!answers && logedIn?.userinfo?._id && <SaveFav v={v} />}
            {v?.notes && <p className='notesBox'><b>Instructions:</b> {v?.notes}</p>}
            {v?.type == 1 && <h3>{v?.question}</h3>}
            {v?.type == 2 && 
                <div className='qVideoPlayer'>
                    <iframe  src={`https://www.youtube.com/embed/${yID}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                    <h3>{v?.video?.video_title}</h3>
                    <p>{v?.video?.video_desc}</p>
                </div>
            }
            {v?.type == 3 && 
                <div className='qVideoPlayer'>
                    {v?.file?.file?.indexOf("undefined") == -1 ? 
                        <audio controls style={{width:"100%"}}>
                            <source src={`${Config?.imgUrl}${v?.file?.file}`} type="audio/mpeg" />
                        </audio>
                    : 
                        <SpeackBtn text={audio && audio} />
                    }
                    <h3>{v?.file?.file_title}</h3>
                    <p>{v?.file?.file_desc}</p>
                </div>
            }
            {v?.type == 4 && 
                <div className='qVideoPlayer'>
                    <img style={{width:"100%", aspectRatio:16/9, objectFit:"cover", borderRadius:10, border:"1px solid #eee"}} src={`${Config?.imgUrl}${v?.file?.file}`} />
                    <h3>{v?.file?.file_title}</h3>
                    <p>{v?.file?.file_desc}</p>
                </div>
            }
            {v?.type == 5 && 
                <div className='qVideoPlayer'>
                    <SpeackBtn text={v?.file?.file_word} />
                    <h3>{v?.file?.file_title}</h3>
                    <p>{v?.file?.file_desc}</p>
                </div>
            }
            
            {v?.answersType == 2 && 
                <div className='answersBox'> 


                    {answers?.length ? <div className='letSqu answersKet'>
                       {letCheck2?.map((v, k) => {
                            return (
                                <span style={{background:"rgb(255, 223, 223)", color:"#555"}} key={k}>{v}</span>
                            )
                        })}
                    </div>: false}


                    <div className='answerLetTr'>
                        <b >{letCheck2Ans}</b>
                    </div>
            
                </div>
                

            }

            {!v?.answersType && 
            <div className='answersBox'>
                {v?.answers?.map((v2, k2) => {

                    let checked;
                    if (answers?.length) {
                        checked = answers?.filter(p => p?.question == v?.ID && p?.answer == k2)?.length;
                    }
                    
                    return (
                        <label style={checked ? {background:"#ffdfdf", padding:"4px 8px"} : {}} key={k2}>
                            
                            {v2?.true ? <GoCheckCircleFill className='radio on'/> : <GoCircle className='radio'/>}
                            <text>{v2?.answer}</text>
                        </label>
                    );
                })}
            </div>
            }

            {/* {answers && <div className='questionComments'>
                <div>
                    <Input 
                        disabled={true}
                        title="Score" 
                        name="user"
                        type="number"
        
                    />
                </div>
            </div>} */}
        </div>
    );
}

export function Img({src, style}) {

    if (!src || src?.indexOf("undefined") > -1) {
        return (
            <img style={style} className="imgPlug" src={`${Config?.imgUrl}Files/nopic.jpg`} />
        );
    } else {
        return (
            <img style={style} className="imgPlug" src={`${Config?.imgUrl}${src}`} />
        );
    }
    
}

export function Tasks({data}) {

    // console.log(data);
    
    const {success, userinfo, showQuiz} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;


    return (
        <div>
            <Link onClick={() => setshowQuizD(data)}>
                <Img src={`img/240x240${data?.img}`} />
                <p>
                    <h3>{data?.name}</h3>
                    <span>
                        {data?.classes?.map((v, k) => {
                            return (
                                <small key={k}>{global.Classes[v]}, </small>
                            );
                        })}
                    </span>
                    
                    {/* <text>{data?.full}</text> */}
                </p>
            </Link>
        </div>
    );
}

export function Games({data}) {

    // console.log(data);
    
    const {success, userinfo, showQuiz} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [showQuizD, setshowQuizD] = showQuiz;

    let allowed = true;
    if (data?.open == 2) {
        if (!logedIn?.userinfo?._id) {
            allowed = false;
        }
    }

    
    
    return (
        <div className='gameListBox'>
            <Link to={allowed ? `/Game/${data?._id}` : false}>
                {!allowed && <b className='lockedBox'><IoLockClosed /></b>}
                <Img src={`img/240x240${data?.img}`} />
                <p>
                    <h3>{data?.name}</h3>
                    <span>
                        {data?.classes?.map((v, k) => {
                            return (
                                <small key={k}>{global.Classes[v]}, </small>
                            );
                        })}
                    </span>
                    <TextTruncate
                        line={4}
                        truncateText="…"
                        text={data?.full}
                        element="text"
                        textElement={"text"}
                    />
                    {/* <text>{data?.full}</text> */}
                </p>
            </Link>
        </div>
    );
}

export default memo(function Randlet({data, finished, id}) {

    function removeLet() {

        if (finished) {
            return false;
        }

        let conf = window.confirm("You are about to remove the whole answer\n are you sure?");
        if (!conf) {
            return false;
        }

        let elem = document.querySelectorAll(`#id-${id} .answersKet span`);
        for (let i = 0; i < elem.length; i++) {
            elem[i].textContent = "";
        }

        let elem2 = document.querySelectorAll(`#id-${id} .ketData b`);
        for (let i = 0; i < elem2.length; i++) {
            elem2[i].classList.remove("disabled");
        }
        
        
    }

    function addLetter(lt, k, elem) {
        if (finished) {
            return false;
        }

        if (elem.target.className == "disabled") {

            return false;
        }


        function detectEmpty() {
            let index = 0;
            let elem = document.querySelectorAll(`#id-${id} .answersKet span`);
            for (let i = 0; i < elem.length; i++) {
                
                if (!elem[i].textContent && !index) {
                    
                    index = i + 1;
                }
            }

            return index;
        }

        let source = elem.target;
        source.classList.add("disabled");
        
        let index = detectEmpty();

        let elem2 = document.querySelector(`#id-${id} .answersKet span:nth-child(${index})`);
		elem2.textContent = lt;

	}

    console.log(data); 


    if (!data) {
        data = "";
    }
    let textRand = data?.split("");

    let randomLet = textRand.sort(() => Math.random() - 0.5); 
    
    return (
        <div className='answersBox'> 

            <div className='letSqu answersKet' data-question={`${id}`}>
                {
                    textRand?.length ? textRand?.map((v2, k) => {
                        return (
                            <span  onClick={removeLet} key={k}></span>
                        )
                    }) : false
                }
            </div>

            <div className='letSqu ketData' style={{ flexWrap:"wrap" }}>
                {
                    randomLet?.length ? randomLet?.map((v2, k) => {
                        
                        return (
                            <>
                                <b onClick={(e) => addLetter(v2, k, e)} key={k}>{v2}</b>
                            </>
                        )
                    }) : false
                }
            </div>
        </div>
    );
    
});