import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import { Img } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";
import { Input, MultiSelect } from "../components/Form";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineCleaningServices, MdOutlineQuestionAnswer } from "react-icons/md";
import { AiOutlineClear } from "react-icons/ai";
import { IoEyeOff, IoEye } from "react-icons/io5";


export default function MyTasks() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [loader2, setloader2] = useState(false);
	const [data, setdata] = useState([]);
	const [showsendTask, setshowsendTask] = useState(false); 
	const [classesList, setclassesList] = useState([]); 

	let loc = useParams();
	let nav = useNavigate();


	function changeStatus(data) {

		let conf = window.confirm(`${text?.about_update_status}`);
		if (!conf) {
			return false;
		}

		let r = {
			score:data?.score,
			finished: data?.finished ? false : true,
			_id:loc?.id,
			student:data?._id,
		}

		setloader2(true);
		Fetch("tasks/updateScore", (d) => {

			if (d?.error) {
				setopen({
                    title:text?.alertErrorTitle,
                    short:d?.error,
                    error:true
                });
			} else {
				loadData();
			}

			
			setloader2(false);
		}, r);
	}


	function loadData() {
		Fetch("tasks/view", (d) => {
			// console.log("asdas");
			if (!d?.error) {
				setdata(d);
				

				let cls = d?.tasks[0];
				if (cls) {
					let arr = [
						{
							name: `- ${text?.select} ${text?.classes} -`,
							val:0
						}
					];
					cls?.classes?.map(v => {
						arr.push({ name:global.Classes[v[0]] + `(${global.Grades[v[2]]})`, val:v });
					});
					setclassesList(arr);
				}
			}

			setloader(false);
		}, { _id: loc?.id, class:loc?.class});
	}
	function deleteStudentAnswers(v) { 

		if (!v?.score) { 
			setopen({
				title:text?.alertErrorTitle,
				short:text?.student_start_yet,
				error:true
			})
			return false;
		}

		let conf = window.confirm(text?.confirm_delete_answers);
		if (!conf) {
			return false;
		}
		setloader2(true);
		Fetch("tasks/delete_student_answes", (d) => {

			if (d?.error) {
				setopen({
					title:text?.alertErrorTitle,
					short:d?.error,
					error:true
				})
			} else {
				loadData();
			}

			setloader2(false);
		}, { task_id: loc?.id, student:v?._id });

	}

	function taskInfo(v) {
		
		if (v?.answers?.length) {
			nav(`/MyTasks/${loc?.id}/${v?._id}`) 
		} else {
			setopen({
				title:text?.alertErrorTitle,
				short:text?.student_start_yet,
				error:true
			})
		}
		

		
	}

	useEffect(() => {

		if (!showsendTask) {
			loadData();
		}
	
	}, [showsendTask, loc?.class]);


	
	if (loader) {
		return (
			<Loader />
		);
	}

	let path = [
		{
			name:text?.home,
			link:"/"
		},
		{
			name:text?.my_tasks,
			link:"/MyTasks"
		},
		{
			name:data?.tasks[0]?.name,
			link:"/MyTasks/" + loc?.id
		}
	];

	if (loc?.class) {
		path.push({
			name:`${global.Classes[loc?.class[0]]} (${global.Grades[loc?.class[2]]})`,
			link:"/"
		},);
	}
	

	return (
		
		<>
			{showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}

			<Header title={path} />

			{loader2 && <Loader />}
			<div className='firstElem stats'>

				<div className='mainWidth'>


					<section>
						<div>
							<b>{text?.stats_myStudents}</b>
							<h3>{data?.stats?.students}</h3>
							{/* <span className='up'><FaArrowUp /> 10.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.stats_grade_avg}</b>
							<h3>{data?.stats?.avg}%</h3>
							{/* <span><FaArrowDown /> 2.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.stats_tasks_sent}</b>
							<h3>{data?.stats?.sent}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>
					


				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>

						

						<div className='containerBox' style={{flex:1, }}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle' style={{fontSize:20, color:"#555", display:"flex", justifyContent:"space-between"}}>
								{text?.students}

								<Input 
									title="" 
									name="Classes"
									type="select"
									
									onChange={(v) => {
										if (v) {
											nav(`/MyTask/${loc?.id}/${v}`);
										} else {
											nav(`/MyTasks/${loc?.id}`);
										}
									}}
									style={{padding:0, maxWidth:200}}
									values={classesList}
									value={loc?.class}
								/>
							</h3>

							<div className='quizzesList'>
								<table>
									<thead>
										<tr>
											<td style={{width:"30%"}} className='align-left'>Student name</td>
											<td>{text?.score}</td>
											<td>{text?.status}</td>
											<td>{text?.classes}</td>
											<td style={{width:"20%"}}>{text?.actions}</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.tasks[0]?.students?.length ? data?.tasks[0]?.students?.map((v, k) => {

											return (
												<tr className={`${v?.score && !v?.finished ? "checked" : "none"}`} key={k}  style={{cursor:"pointer"}} >
													<td  className='align-left'>
														<a onClick={() => taskInfo(v)} style={{display:"flex", alignItems:"center", gap:5}}>
															<Img style={{width:40, height:40, objectFit:"cover", borderRadius:"50%"}} src={'img/60x60' +  v?.img}/>
															<span style={{fontSize:13}}>{v?.name}</span>
														</a>
													</td>
													<td>
														{v?.score && v?.finished ? <span className='tag green'>{v?.score}%</span> : false}
														{v?.score && !v?.finished ? <span className='tag green'>{v?.score}%</span> : false}
														{!v?.score && !v?.finished ? "--" : false}
														{!v?.score && v?.finished ? "--" : false}
													</td>
													<td>
														{v?.score && v?.finished ? <span className='tag blue'>{text?.finished}</span> : false}
														{v?.score && !v?.finished ? <span className='tag orange'>{text?.needs_attention}</span> : false}
														{!v?.score && !v?.finished ? <span className='tag gray'>{text?.didnt_start}</span> : false}
														{!v?.score && v?.finished ? <span className='tag orange'>{text?.needs_attention}</span> : false}
													</td>
													<td>{global.Classes[v?.classes[0][0]]} ({global.Grades[v?.classes[0][2]]})</td>
													<td >
														<a onClick={() => taskInfo(v)} className='tableOptions blue'><MdOutlineQuestionAnswer /></a>
														{v?.score ? <>&nbsp;&nbsp;
														<Link onClick={() => changeStatus(v)} className='tableOptions gray'>{v?.finished ? <IoEyeOff /> : <IoEye />}</Link></> : false}
														&nbsp;&nbsp;
														<Link onClick={() => deleteStudentAnswers(v)} className='tableOptions red'><AiOutlineClear /></Link>
													</td>
												</tr>
											);
										}) : false}
										

										

									</tbody>
								</table>
							</div>
							
						</div>

						<div className='containerBox' style={{width:"30%"}}>
						
							<hr className='boxLine'/>
							<h3 className='boxTitle' style={{fontSize:20, color:"#555"}}>Quiz info</h3>

							<div className='quizInfoBox'>
								<a>
									<span>{text?.classes}</span>
									<b>
										{data?.tasks[0]?.classes?.map((v, k) => {
											
											let classNames = global.Classes[v[0]];
											let gradeNames = global.Grades[v[2]];

											return ( 
												<t key={k}>{classNames} ({gradeNames}), </t>
											);
										})}
									</b>
								</a>
								
								<a>
									<span>{text?.timer}</span>
									<b>{data?.tasks[0]?.timer ? `${data?.tasks[0]?.timer} ${text?.minutes}` : text?.unlimited}</b>
								</a>
								<a>
									<span>{text?.results}</span>
									<b>{data?.tasks[0]?.res_type ? text?.automatically : text?.teacher_decision}</b>
								</a>

								<a>
									<span>{text?.added}</span>
									<b>{dateToText(data?.tasks[0]?.time)}</b>
								</a>
								<a>
									<span>{text?.start_at}</span>
									<b>{dateToText(data?.tasks[0]?.date_start)}</b>
								</a>
								<a>
									<span>{text?.ends_at}</span>
									<b>{data?.tasks[0]?.date_end ? dateToText(data?.tasks[0]?.date_end) : text?.unlimited}</b>
								</a>

								<a>
									<span>{text?.comments}</span>
									<b>{data?.tasks[0]?.full ? data?.tasks[0]?.full : "--"}</b>
								</a>


							</div>
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
	
}
