import { memo, useEffect, useState, useContext, useRef } from 'react';

import './css/QuizPrev.css'
import manSrc from '../../images/moe.png'

import { Config } from '../../inc/Config'
import { AppContext } from "../../Context";
import Popups from "../../components/Popups";
import { Question } from "../../components/Elements";

import { SaveFav } from '../../inc/functions'
import Fetch, { Loader, dateToText } from "../../inc/Fetch";

import { RiQuestionAnswerLine } from "react-icons/ri";



export default memo(function QuizPrev({setopen, open, openTasks}) {

    const active = useRef(true);

    const {success, userinfo, loginForm} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [openLogin, setopenLogin] = loginForm;

    const [loader, setloader] = useState(false);


    
    return (

        <>
        
        <Popups setOpen={setopen} style={{maxWidth:700, borderTop:"4px solid #fd745a", maxHeight:"96vh"}}>
            {loader && <Loader />}
           <>

                <div className='popupBody interface data' >

                    <div className='quizPrevBox'>
                        <div className='quizInfo'>

                            <div className='left'>
                                <img src={`${Config?.imgUrl}img/300/${open?.img}`} />
                                <div className='infoText'>
                                    <h2>{open?.name}</h2>
                                    <p>{open?.full}</p>
                                    <div className='moreInfo'>
                                        <text><RiQuestionAnswerLine />Questions: <b>{open?.questions?.length}</b></text>
                                        <text className='green'><RiQuestionAnswerLine />Category: <b>{open?.cats[0]?.name}</b></text>
                                        <text className='blue'><RiQuestionAnswerLine />Level: <b>{global.Classes[open?.classes[0]]}</b></text>
                                    </div>
                                </div>
                            </div>
                            
                        </div>


                        <div className='questionsBox'>
                            {open?.story && 
                                <div dangerouslySetInnerHTML={{__html:`<h2>Content</h2>${open?.story}`}} className='storyBox'>
                                </div>
                            }
                            {open?.questions?.map((v, k) => {
                                return (
                                    <Question v={v} key={k} />
                                );
                            })}
                            
                        </div>

                        <div className='quizBtnBox'>

                            <button onClick={() => setopen(false)} className='btn gray'>Cancel</button>
                            {logedIn?.userinfo?._id ? 
                                <button onClick={() => { openTasks({questions: open?.questions, story:open?.story }); setopen(false) }} className='btn'>Send as a Task</button>
                            :
                                <button onClick={() => { setopenLogin(true); }} className='btn'>LOG IN</button>
                            }
                            
                        </div>
                        
                    </div>

                    
                </div>
            </> 

            

        </Popups>
        </>
    );
})